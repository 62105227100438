import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ProductService {

  env = environment;
  constructor(private http: HttpClient) { }

  getVccbPersonalLoanScore(params: any) {
    return this.http.get(this.env.ccAgentWebApiUrl + 'api/CcProduct/GetVccbPersonalLoanScore', { params });
  }

  getProductsQualify(params: any) {
    return this.http.get(this.env.ccAgentWebApiUrl + 'api/CcProduct/GetProductsQualify', { params });
  }

  // Discontinuing - Credit Life Cover Product

  // UserToCreditLifeCalculator(params: any) {
  //   return this.http.post(environment.ccAgentWebApiUrl + 'api/CcProduct/CreditLifeCalculator', params);
  // }
}
