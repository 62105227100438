import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class NotificationService {

  env = environment;
  constructor(private http: HttpClient) { }

  SendNotification(data: any) {
    return this.http.post(environment.ccAgentWebApiUrl + 'api/Notifications/SendNotification',  data);
  }
}